module.exports = {
  language: {
    title: "language",
    zh: "简体中文",
    en: "English",
  },
  menus: {
    home: "Home",
    market: "Market",
    bid: "Bid",
    ava: "Ava",
    create: "Create",
    profile: "Profile",
    exchange: "Exchange",
    artist: 'Artist'
  },
  profile: {
    myart: "My Artworks",
    myfavorite: "My Collection",
    createNow: "Create Now",
    mine: 'Mine',
    connectWallet: 'Connect wallet',
    available: 'Available',
    status: 'Status',
    unStake: 'Unstake',
    staked: 'Staked',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    withdrawProfit: 'Withdraw profit',
    import: 'Import',
    gallery: 'My Gallery',
    impTitle: 'Import NFT Contract by creation address',
    impTip: 'Contract create hash',
    impHolder: 'Copy contract creation hash(not contract address) to here',
  },
  footer: {
    links: "Quick Link",
    contact: "Contact Us",
    resources: "Resources",
    nav: {
      about: "About MetaBase.art",
      whitePaper: "White Book",
      litePaper: "https://hgt.static.kingco.tech/MetaBase.artWhitePaperV20210912.pdf",
    },

    link: {
      crosschain: "Cross-chain Bridge",
      help: "Help",
      helpURL: "https://en.docs.metabase.art",
      preview: "VR Preview",
      metaworld: "MetaWorld"
    }
  },
  banner: {
    line1: "Create your own ",
    line2: "Digital Artwork NFT easily",
  },
  property: {
    title1: "Easy to Create",
    desc1: "Digital Artwork is at you hand", //这里文字多一点可以换行
    title2: "Easy to Trade",
    desc2: "Just one step from Creation to Trade",
    title3: "Your right forever",
    desc3: "You will earn from EVERY transaction",
  },
  top_art: {
    more_btn: "More",
    create_btn: "Create",
  },
  step: {
    title1: "Connect Wallet",
    desc1: `MetaBase.art is built on High Performance blockchain, please connect you wallet first`,
    title2: "Create Artwork",
    desc2: `Create digital artworks from the AI-based tools. Easy to use ,everyone can be artist.`,
    title3: "Trade NFT",
    desc3: `You can set price and royalty of you artwork, It will be onsale and creator can earn from EVERY transaction`,
  },
  message: {
    webtitle: "MetaBase",
    zh: "Chinese",
    en: "English",
    view_btn_title: "Browse",
    new_art_title: "Newest Artwoks",
    create_art_step: "Eash to create and share your digital artwoks",
    recommend_title: "Recommendations",
    view_more_art: "Browse more artworks",
  },
  builder: {
    writing: "Chinese Writings",
    ascii: "ASCII Arts",
    pixel: "Pixel Arts",
    selectImage: "Select Image",
    inputKeyword: "Please  input Keyword",
    vertical: "Vertical Align",
    horizontal: "Horizontal Align",
  },
  filter: {
    types: {
      all: "All Items",
      writing: "Chinese Writings",
      ascii: "ASCII Arts",
      pixel: "Pixel Arts",
      custom: "User Uploaded"
    },
    sorts: {
      all: "Sort By",
      lowToHigh: "Price: Low to High",
      highToLow: "Price: High to Low",
      recently: "Recently Created",
      oldest: "Oldest",
    },
  },
  list: {
    empty:
      "Empty list(If you just minted artwork, please wait for a few while to be listed)",
  },
  about: {
    title: "About MetaBase.art",
    titleShort: "MetaBase",
    about: "About",
    home: "Home",
    content:
      "MetaBase.art is a platform that everyone can create and share NFT digital artwork. Let us create the NFT digital artwork easy and funny. Let us make the metaverse more beautiful！",
    createTitle: "Create",
    createTip:
      "We create generated digital artworks  based on AI, It very easy to everyone to generate artwork",
    tradeTitle: "Trade",
    tradeTip:
      "Share and Trading on marketplace, NFT protect your rights and you will earn from every transaction",
  },
  bid:{
    bidTitle: "Bid digital art",
    bidTip: "1 NFT for bidding every 8 hours",
    typeAvatar: " Pixel Avatars",
    typeAlgo: "Algorithm",
    generateAvatar: "Playground",
    currentBid: "Current Bid",
    currentNo: "Current No",
    currentEnd: "Endtime",
    bidRecords: "Bid records",
    settledAvatar: "Last NFTs",
    joinBid: "Bid",
    feeType: "Bid Fee",
    feeNeed: "Fee Needed：",
    feeBuy: "Get",
    coming: 'Coming',
    idle: 'Take a look',
    bid: 'Bid',
    min: 'Min',
    balance: 'Balance',
    next: 'Next',
    inputPrice: 'Please input price',
    noPrice: 'Please input price',
    priceTooLow: 'Price too low',
    networkError: 'Network error',
    endsIn: 'Ends in',
    bidder: "Biddeer",
    bidprice: "Price",
    waitNext: "Please prepare for next bid",
    lowBalance: "Insufficient balance, please recharge first",
  },
  create: {
    createTitle: "Create Digital Artworks",
    createTip:"Select type to create, supply content then artworks will be generated automatically by AI",
    publishTitle: "Publish Artworks",
    publishTip: "Artist can upload artworks or generate Calligraphy artworks with your poems",
    typeWriting: " Calligraphy",
    typeAscii: " ASCII Art",
    typePixel: " Pixel Art",
    typeDraw: " Upload",
    generateWriting: "Generate Calligraphy",
    searchByKeyword: "Search content from our library",
    connectWallet: "Please connect Wallet ",
    connectChain: "Please choose the right blockchain",
    royaltyNotValid: "Royalty can not be more than 8%",
    notEnoughFund: "Not enough fund!",
    feeTypeTip: "Please select fee type",
    noArtwork: "No artwork generated",
    noPicture: "No picture",
    maxPicture: "The maximum uploaded image is 2M",
    writingFirst: "Please generate calligraphy first",
    waiting: "Generating , please wait for a few seconds...",
    clickAndDrag: "Click and drag to upload your picture",
    feeType: "Generation Fee",
    feeTypeTipLong: "Select which token to be used to pay generation fee",
    balance: "Balance：",
    feeNeed: "Fee：",
    royalty: "Royalty rate",
    royaltyTip:
      "You can earn this percent of total price from each trade transaction",
    createNFT: "Create NFT",
  },
  detail: {
    "description": "Description",
    "details": "Details",
    "contractAddr": "Contract Address",
    "tokenId": "Token ID",
    "tokenStandard": "Token Standard",
    "blockchain": "Blockchain",
    "metadata": "Metadata",
    "editable": "Editable",
    createdBy: "Created by",
    "ownedBy": "Owned by",
    "sales": "Sales",
    "currentPrice": "Current price",
    "notConnect": "Not Connected",
    "notForSell": "Not For Sell",
    "eth": "ETH",
    "usdt": "USDT",
    "priceHistory": "Price History",
    "tradingHistory": "Trading History",
    "event": "Event",
    "vr": "VR",
    "view":"Explore",
    "price": "Price",
    "from": "From",
    "to": "To",
    "date": "Date",
    "blocks": "Blocks",
    "sale": "Sale",
    "cancel": "Take Off",
    "Sell": "Sell",
    "edit": "Edit",
    "minted": "Minted",
    "onSale": "Set the Price",
    "inputPrice": "Please input Price",
    "buyNow": "Buy Now",
    dlgConfirm: "Confirm",
    dlgCancel: "Cancel",
    dlgToken: "Pay by",
    dlgPrice: "Price",
    content: "Content",
    dlgConfirmBuy: "Are you sure to buy?",
    dlgConfirmOffSale: "Are you sure to take off?",
    royalty: "Royalty",
    inputRoyalty: "Please input royalty rate",
    minPrice: "Min price",
    inputMinPrice: "Please input min sell price",
  },
  exchange:{
    "title": "Exchange HGT for XHGT",
    inputtip:"Please input HGT amount",
    balance:"Balance",
    exchange:"Exchange",
    inputalert:"Please input the amount",
  },
  avatar:{
    title: "Mint Your NFT",
    title2: "Your Own NFT",
    desc: "NFT can create new identity, reputation and self-expression for users. When NFT is no longer a separate product but a comprehensive experience, it will bring a new picture to users and communities - their preferences, preferences, wishes, expectations, etc. can be verified and stored in the chain.",
    price: "Cost 0.33HT, total limit 10000, rest ",
    btnstart: "Start Mint Your MetaWorld Avatar",
    btnstart2: "Invite You to Mint MetaWorld Avatar",
    btnalready: "Click to Invite Friends Can Earn HT",
    inviteTitle: "Invite Reward",
    inviteDesc: "You can earn 0.03HT for every NFT invited by you!",
    inviteBtn: "Invite Mint NFT，Earn HT",
    adTitle: "Unique attributes bring exclusive functions",
    adContent: "In fact, they are not just pixels. Each pixel has a unique set of features that can be traded and collected.",
    lowBalance: "Insufficient balance, please recharge first",
    nonft: "Please mint your own Avatar first",
    shareurl: "The URL to the pasteboard is successfully generated. Please share it with your friends to earn rewards!",
    sharefail: "The browser does not support copying. Please share the URL to your friends to earn rewards!",
    info1title: "Limited Sale",
    info1content: "The total amount is only 10000, and each address is limited to 1, no additional issuance",
    info2title: "Only Exclusive",
    info2content: "Use intelligent algorithm to dynamically generate, unique, and create NFT that only belongs to you",
    info3title: "Metaverse",
    info3content: "Create your MetaWorld avatar image",
    info4title: "Transfer Free",
    info4content: "Market pending orders, independent pricing and free transfer",
  },
  gallery:{
    welcome: "Welcome to Gallery",
    hello: "Hello, I am ",
    help: ", what can I do for you?",
    working: "Working",
    nowork: "No Work",
    work: "Work",
    rest: "Rest"
  }
};
