import axios from "axios"
import instance from "../main"

const appBaseURL = `${process.env.VUE_APP_API_DOMAIN}`

class BaseModule {
  constructor (baseURL = appBaseURL) {
    this.$http = axios.create({
      baseURL
    })
    this.$http.interceptors.request.use(
      config => {
        config.headers.lang = instance.$i18n.locale
        return config
      },
      error => Promise.reject(error)
    )
    this.$http.interceptors.response.use(
      response => {
        switch (response.data.code) {
          case 101:
            let nowPath = window.location.href.split('#')[1]
            if (nowPath) {
              instance.$router.push({ name: "login", query: { redirectPath: encodeURIComponent(nowPath) } })
            } else {
              instance.$router.push("/login")
            }
            instance.$store.commit("logOutUser")
            return Promise.reject(response)
          case 0:
            return Promise.reject(response)
          default:
            return response
        }
      },
      error => Promise.reject(error)
    )
  }

  get (url, config = {}) {
    return this.$http.get(url, config)
  }

  post (url, data = undefined, config = {}) {
    return this.$http.post(url, data, config)
  }

  put (url, data = undefined, config = {}) {
    return this.$http.put(url, data, config)
  }

  delete (url, data = undefined, config = {}) {
    return this.$http.delete(url, data, config)
  }
}

export default BaseModule
