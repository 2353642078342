<template>
  <v-row>
    <!-- Start Single Counterup  -->
    <v-col
      lg="4"
      md="4"
      sm="4"
      cols="12"
      v-for="(counter, i) in counterUpContent"
      :key="i"
    >
      <div class="rn-counterup counterup_style--1">
        <h5 class="counter count" >
          {{$t(counter.endVal)}}
        </h5>
        <p class="description">
          {{ $t(counter.desc) }}
        </p>
      </div>
    </v-col>
    <!-- Start Single Counterup  -->
  </v-row>
</template>

<script>

  export default {
    components: {
    },
    data() {
      return {
        countUp: true,
        status: false,
        counterUpContent: [
          {
            endVal: "step.title1",
            desc: "step.desc1",
          },
          {
            endVal: "step.title2",
            desc: "step.desc2",
          },
          {
            endVal: "step.title3",
            desc: "step.desc3",
          },
        ],
      };
    },
    methods: {
      onChange(isVisible) {
        if (isVisible) {
          this.status = true;
        }
      },
    },
  };
</script>
