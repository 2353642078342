<template>
  <div>
    <!-- Start Portfolio Activation  -->
    <VueSlickCarousel
      v-bind="settings"
      class="rn-slick-activation portfolio-slick-activation item-fluid slick-dotted rn-slick-dot mt--40 mt_sm--40"
    >
      <!-- Start Single Portfolio  -->
      <div class="portfolio" v-for="(item, i) in portfolioContent" :key="i">
        <div class="thumbnail-inner" v-if="item.metadata">
          <v-img class="thumbnail" :src="item.metadata.image"></v-img>
          <v-img class="bg-blr-image" :src="item.metadata.image"></v-img>
        </div>
        <div class="content">
          <div class="inner">
            <p>{{ item.meta }}</p>
            <h4>
              <router-link :to="'/meta/detail?nftToken=' + item.contractAddr + '&tokenId=' + item.tokenId + '&ownerAddr=' + item.ownerAddr">{{ item.name }}</router-link>
            </h4>
            <div class="portfolio-button">
              <router-link class="rn-btn" :to="'/meta/detail?nftToken=' + item.contractAddr + '&tokenId=' + item.tokenId + '&ownerAddr=' + item.ownerAddr"
                >{{$t("message.view_btn_title")}}
              </router-link >
            </div>
          </div>
        </div>
      </div>
      <!-- End Single Portfolio  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  import MetaBase from "@/api/MetaBase";
  import { mapGetters, mapState } from "vuex";
  export default {
    components: {
      VueSlickCarousel,
    },
    data() {
      return {
        total: 0,
        portfolioContent: [{
          src: require("../../assets/images/portfolio/portfolio-2.jpg"),
          meta: "用户名",
          title: "作品标题",
        }, {
          src: require("../../assets/images/portfolio/portfolio-2.jpg"),
          meta: "用户名",
          title: "作品标题",
        }, {
          src: require("../../assets/images/portfolio/portfolio-2.jpg"),
          meta: "用户名",
          title: "作品标题",
        }, {
          src: require("../../assets/images/portfolio/portfolio-2.jpg"),
          meta: "用户名",
          title: "作品标题",
        }, {
          src: require("../../assets/images/portfolio/portfolio-2.jpg"),
          meta: "用户名",
          title: "作品标题",
        }, {
          src: require("../../assets/images/portfolio/portfolio-2.jpg"),
          meta: "用户名",
          title: "作品标题",
        }],
        // for all works
        settings: {
          dots: true,
          arrows: true,
          infinite: true,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 1263,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              },
            },
          ],
        },
      };
    },

    async created(){
       await this.handleConnect()
       this.loadNewestArt()
    },
    computed: {
      ...mapGetters(["account", "chainId"]),
      ...mapState(["web3"])
    },
    methods: {
      async handleConnect() {
        if (!this.web3 || !this.web3.account) {
          console.log("connecting");
          await this.$store.dispatch("connectWeb3");
        }
      },
      async loadNewestArt(){
        if (this.chainId == 0) {
          return this.$message.error("请连接钱包");
        }
        // let result = await MetaBase.getOnsaleNFTListByTime({
        //   chainId: this.chainId
        // });
        let result = await MetaBase.marketList({
          chainId: this.chainId,
          orderBy: 'updated_at',
          orderIn: 'DESC',
          pageIndex: 1,
          pageSize: 8,
        });
        this.total = result.data.data.total;
        this.portfolioContent = result.data.data.data;
        console.log({debug: this.portfolioContent})
      }
    }
  };
</script>

<style>
div.v-image__image.v-image__image--cover {
  /*background-position*/
  background-size: cover;
}
</style>
