<template>
<div
      class="breadcrumb-area rn-bg-color bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner">
              <h3 class="heading-title"></h3>
            </div>
          </v-col>
        </v-row>
      </v-container>
</div>
</template>
<script>
export default {}
</script>
<style scoped>

.breadcrumb-area {
    padding: 80px;
}

@media (max-width:960px) {
    .breadcrumb-area {
        padding: 60px;
    }
}

</style>