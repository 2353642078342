module.exports = {
  language: {
    title: "切换语言",
    zh: "中文",
    en: "English",
  },
  menus: {
    home: "首页",
    market: "市场",
    create: "创作",
    bid: "竞拍",
    ava: "阿娃",
    profile: "我的",
    exchange: "兑换",
    artist: '艺术家'
  },
  profile: {
    myart: "我的作品",
    myfavorite: "我的收藏",
    createNow: "现在去创作",
    mine: '抵押挖矿',
    connectWallet: '连接钱包',
    available: '可提取',
    status: '状态',
    unStake: '未抵押',
    staked: '已抵押',
    deposit: '抵押',
    withdraw: '解押',
    withdrawProfit: '提取收益',
    import: '导入',
    gallery: '我的展厅',
    impTitle: '通过合约创建hash导入NFT合约',
    impTip: '合约创建交易hash',
    impHolder: '复制创建合约的交易hash(注意不是合约地址)到这里',
  },
  footer: {
    links: "链接",
    contact: "联系我们",
    resources: "资源",
    nav: {
      about: "关于 MetaBase.art",
      whitePaper: "白皮书",
      litePaper:
        "https://hgt.static.kingco.tech/MetaBase.art%E7%AE%80%E7%89%88%E7%99%BD%E7%9A%AE%E4%B9%A6V20210912.pdf",
    },
    link: {
      crosschain: "跨链桥",
      help: "帮助",
      helpURL: "https://docs.metabase.art",
      preview: "VR预览",
      metaworld: "Meta世界"
    }
  },
  banner: {
    line1: "轻松创作",
    line2: "专属的数字艺术",
  },
  property: {
    title1: "轻松上手",
    desc1: "元宇宙NFT就在您的手边", //这里文字多一点可以换行
    title2: "一键交易",
    desc2: "从创作到交易只有一键之遥",
    title3: "永久确权",
    desc3: "创作者享有每一次流转收益",
  },
  top_art: {
    more_btn: "浏览更多作品",
    create_btn: "创建我的作品",
  },
  message: {
    webtitle: "ENS Avatar",
    view_btn_title: "查看",
    new_art_title: "最新作品",
    create_art_step: "轻松创建和销售你的数字艺术NFT",
    recommend_title: "作品推荐",
    view_more_art: "查看更多新作品",
  },
  step: {
    title1: "链接钱包",
    desc1: `MetaBase.art是在高性能公链上的数字艺术创作与分享平台，请将您的钱包链接到公链`,
    title2: "创建作品",
    desc2: `MetaBase.art开发了专属的数字艺术生成工具，轻松上手， 让人人都可以创建数字艺术NFT`,
    title3: "交易NFT",
    desc3: `为您的作品设置价格和版税，作品就会在市场中显示，创作者永久获得流转收益。`,
  },
  builder: {
    writing: "书法艺术",
    ascii: "字符艺术",
    pixel: "象素艺术",
    selectImage: "选择图片(小于2M)",
    inputKeyword: "查找书法素材",
    vertical: "竖版",
    horizontal: "横版",
  },
  filter: {
    types: {
      all: "请选择类型",
      writing: "书法艺术",
      original: "原创艺术",
      ascii: "字符艺术",
      pixel: "像素艺术",
      custom: "自创作品"
    },
    sorts: {
      all: "请选择",
      lowToHigh: "价格由低到高",
      highToLow: "价格由高到低",
      recently: "上架时间降序",
      oldest: "上架时间升序",
    },
  },
  list: {
    empty: "空空如也<br />（如果您新生成了 NFT，请稍候，链上确认成功后将显示在这里）",
  },
  about: {
    title: "关于 MetaBase.art",
    titleShort: "ENS Avatar",
    about: "关于",
    home: "首页",
    content:
      "MetaBase.art 是一个任何人都可以创作和分享 NFT 数字艺术品的平台，我们的愿景是让 NFT 数字艺术品的创作变得简单有趣，让 NFT 数字艺术品为元宇宙添砖加瓦。",
    createTitle: "创作",
    createTip: "基于人工智能技术，让 NFT 数字艺术品的创作，变得非常轻松",
    tradeTitle: "交易",
    tradeTip: "一键分享到市场，NFT 的永久确权，让作品获得持续的流通收益",


  },
  bid:{
    bidTitle: "竞拍数字艺术品",
    bidTip: "每种纯程序数字艺术品每天生成3张，价高者得",
    typeAvatar: " 阿娃形象",
    typeAlgo: " 算法艺术",
    generateAvatar: "随便看看",
    currentBid: "当前竞拍",
    currentNo: "当前编号",
    currentEnd: "本期结束",
    bidRecords: "竞拍记录",
    settledAvatar: "往期作品",
    joinBid: "参加竞拍",
    feeType: "竞拍费",
    feeNeed: "至少需要：",
    coming: '即将上线',
    idle: '随便看看',
    bid: '竞拍',
    min: '最低',
    balance: '余额',
    next: '换一个',
    inputPrice: '请输入竞拍价格',
    noPrice: '请输入价格',
    priceTooLow: '竞拍价格需要大于最低价格',
    networkError: '网络异常',
    endsIn: '剩余时间',
    bidder: "竞拍人",
    bidprice: "出价",
    waitNext: "本期已结束，请等待下次竞拍",
    lowBalance: "余额不足，请先充值",
  },
  create: {
    createTitle: "创作数字艺术品",
    publishTitle: "发布艺术作品",
    createTip: "选择艺术品类型和素材，智能生成对应的艺术品",
    publishTip: "艺术家可以直接上传作品，或者填入诗词等作品内容，生成书法",
    typeWriting: " 书法艺术",
    typeAscii: " 字符艺术",
    typePixel: " 像素艺术",
    typeDraw: " 上传作品",
    generateWriting: "生成书法",
    searchByKeyword: "请输入关键词从库中检索",
    connectWallet: "请连接钱包",
    connectChain: "请切换至正确的链",
    royaltyNotValid: "版税不能大于8%",
    notEnoughFund: "余额不足",
    feeTypeTip: "请选择手续费类型",
    noArtwork: "未生成艺术品",
    noPicture: "请选择图片",
    maxPicture: "最大上传图片为2M",
    writingFirst: "请先生成书法艺术品",
    selectWritingFirst: "请先检索要生成书法的内容",
    inputWritingFirst: "请先输入要生成书法的内容",
    waiting: "正在生成，请稍候...",
    clickAndDrag: "点击或者拖拽上传图片素材",
    feeType: "手续费",
    feeTypeTipLong: "选择用于支付铸造手续费的币种",
    balance: "余额：",
    feeNeed: "手续费：",
    feeBuy: "获得",
    royalty: "版税比率",
    royaltyTip: "每一次交易，创作者都将获得相应比例的版税",
    createNFT: "创建 NFT",
  },
  detail: {
    description: "说明",
    details: "详情",
    contractAddr: "NFT合约",
    tokenId: "艺术品编号",
    tokenStandard: "协议标准",
    blockchain: "公链",
    metadata: "元数据",
    editable: "可编辑",
    createdBy: "创建者：",
    ownedBy: "收藏者：",
    sales: "上架信息",
    currentPrice: "当前价格",
    notConnect: "未连接",
    notForSell: "未上架",
    eth: "ETH",
    usdt: "USDT",
    priceHistory: "价格历史",
    tradingHistory: "交易历史",
    event: "事件",
    "vr": "元宇宙",
    "view":"立即体验",
    price: "价格",
    from: "从（卖方）",
    to: "到（买方）",
    date: "日期",
    blocks: "区块",
    sale: "售出",
    sell: "上架",
    cancel: "下架",
    edit: "调价",
    minted: "生成",
    onSale: "设置价格并上架",
    inputPrice: "请输入价格",
    buyNow: "现在购买",
    dlgConfirm: "确认",
    dlgCancel: "取消",
    dlgToken: "币种",
    dlgPrice: "价格",
    content: "内容",
    dlgConfirmBuy: "确定购买？",
    dlgConfirmOffSale: "确定下架吗",
    royalty: "版税",
    inputRoyalty: "请输入版税",
    minPrice: "最低售卖价格",
    inputMinPrice: "请设置最低售卖价格",
  },
  exchange:{
    "title": "HGT兑换XHGT",
    inputtip:"请输入HGT的数量",
    balance:"余额",
    exchange:"兑换",
    inputalert:"请输入兑换数量",
  },
  avatar:{
    title: "铸造你的ENS Avatar",
    title2: "属于你的ENS Avatar",
    desc: "完全去中心化 ENS Avatar NFT 可为用户创造新的身份、声誉和自我表达方式。",
    pricePrefix: "仅需",
    priceSuffix: "，限量 10000，剩余",
    btnstart: "创建 ENSAvatar 形象",
    btnstart2: "推荐你创建 ENSAvatar 形象",
    btnalready: "你已创建，点我邀请好友赚取奖励",
    inviteTitle: "邀请奖励",
    inviteDescPrefix: "每邀请创作一个NFT，你将获得",
    inviteDescSuffix: "！",
    inviteBtn: "邀请好友创建，赚取奖励",
    adTitle: "独特属性带来专属的功能",
    adContent: "事实上，它们并不仅仅是象素。每个像素都有一套独特的特征，可以交易和收藏。",
    lowBalance: "余额不足，请先充值",
    nonft: "请先创建自己的个性NFT",
    shareurl: "成功生成链接到粘贴板，请分享给好友赚取奖励！",
    sharefail: "浏览器不支持复制，请分享网址给好友赚取奖励！",
    info1title: "限量发售",
    info1content: "总量仅 10000 个，先到先得",
    info2title: "唯一专属",
    info2content: "采用智能算法动态生成，链上存储，独一无二",
    info3title: "元宇宙",
    info3content: "开创你的Meta世界形象，现在即可体验",
    info4title: "自主转移",
    info4content: "可市场挂单，自主定价，自由转移",
  },
  gallery:{
    welcome: "欢迎您来到展厅",
    hello: "您好，我是 ",
    help: ", 请问您需要什么帮助?",
    working: "工作",
    nowork: "未工作",
    work: "开工",
    rest: "收工"
  }
};
