<template>
  <v-row>
    <!-- Start Blog Area  -->
    <v-col
      lg="3"
      md="3"
      sm="6"
      cols="12"
      v-for="(item, i) in recommendItems"
      :key="i"
    >
      <token-card
          :nftAddr="item.contractAddr"
          :price="item.realPrice > 0 ? $options.filters.toNumber(item.realPrice) + ' ' + item.payTokenSymbol : '未上架'"
          :tokenId="item.tokenId"
          :tokenUri="item.metadata.image"
          :ownerAddr="item.ownerAddr"
          :title="item.name"
      ></token-card>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
  import TokenCard from "./TokenCard";
  export default {
    components: {TokenCard},
    props:{
      recommendItems: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    mounted() {
      console.log(this.recommendItems)
    },
    data() {
      return {
      };
    },
  };
</script>
