/*
 * @Author: your name
 * @Date: 2021-09-12 11:23:48
 * @LastEditTime: 2021-09-12 12:31:19
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /nft-web/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueParticles from "vue-particles";
import CoolLightBox from 'vue-cool-lightbox';
import VueScrollactive from 'vue-scrollactive';
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/material-icon.scss'
import VueI18n from 'vue-i18n'
import Vuex from "vuex";
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/scss/main.scss';
import './assets/scss/custom.scss';
import BigNumber from "bignumber.js";
import VueClipboard from 'vue-clipboard2'

import axios from "axios"
Vue.prototype.$http = axios

Vue.use(Antd);
Vue.use(Vuex)

Vue.config.productionTip = false;
Vue.use(VueParticles);
Vue.use(CoolLightBox);
Vue.use(VueScrollactive);
Vue.use(VueI18n)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

const i18n = new VueI18n({
  local: 'zh-CN',
  messages: {
      'zh-CN': require('./lang/zh.js'),
      'en-US': require('./lang/en.js'),
  }
})

i18n.locale = 'en-US'

Vue.filter('shortStr', function (value) {
  if (!value || value.length < 15) return ''
  return value.substr(0, 6) + '...' + value.substr(-6)
})

Vue.filter('shortNum', function (value, decimal = 4) {
  const base = Math.pow(10, decimal)
  return Math.round(value * base) / base
})

Vue.filter('divDecimal', function (value, decimal = 18) {
  // return value
  let bigBase = new BigNumber('1e' + decimal)
  let bigAmount = new BigNumber(value)
  return bigAmount.div(bigBase).toString()
})

Vue.filter('toNumber', function(value) {
  if (typeof value !== 'string') return value
  return new BigNumber(value).toNumber()
})

Vue.filter('tsToTime', function(ts) {
  let date = new Date(ts * 1000);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;
  second = second < 10 ? ('0' + second) : second;
  return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;
})

Vue.filter('countdownStr', function(ts) {
  let h = parseInt(ts / 3600)
  h = h < 10 ? ('0' + h) : h;
  let m = parseInt((ts %3600) / 60)
  m = m < 10 ? ('0' + m) : m;
  let s = ts % 60
  s = s < 10 ? ('0' + s) : s;
  return `${h}:${m}:${s}`
})

Vue.filter('formatAmount', function(amount, decimal = 6) {
  let str = parseFloat(amount).toFixed(decimal)
  return parseFloat(str)
})

// store.dispatch("connectWeb3")
const AppInstance =  new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

export default AppInstance
