import { message } from 'ant-design-vue';

async function sendTx(contract, method, account, params, value = 0, type = 'Transaction') {
  console.log({method, account, params, value, type})
  try {
    message.destroy()
    message.loading(`${type} is submiting，please confirm...`, 0)
    let encodedData = await contract.contract.methods[method](...params).encodeABI()
    console.log({chainId: '0x' + contract.web3.chainId.toString(16)})
    let estimate = await contract.web3.eth.estimateGas({
      chainId: '0x' + contract.web3.chainId.toString(16),
      from: account,
      data: encodedData,
      value: value,
      to: contract.config.contractAddr
    })
    console.log({estimate})
    // 获取矿工费
    // let gasPrice = await contract.web3.eth.getGasPrice()
    return await asyncSend(contract, method, account, params, value, type)
  } catch (e) {
    message.destroy()
    console.log('sendTxError', e)
    let msg = e.message
    const matches = msg ? msg.match(/"message": "(.*)"/) : []
    if (matches && matches[1]) {
      msg = matches[1]
    }
    message.error(msg, 10)
  }
}

async function asyncSend(contract, method, account, params, value, type) {
  let gasPrice = await contract.web3.eth.getGasPrice()
  console.log("gasPrice " + gasPrice)
  return new Promise((resolve, reject) => {
    contract.contract.methods[method](...params).send({
      from: account, value: value, chainId: '0x' + contract.web3.chainId.toString(16), gasPrice
    }).on('transactionHash', (txHash) => {
      console.log({txHash})
      message.destroy()
      message.loading(`${type} Submit successfull, need to be mined...`, 0)
      let timer = setInterval(() => {
        console.log('checkTxStatus', txHash)
        contract.web3.eth.getTransactionReceipt(txHash, (err, res) => {
          console.log('checkResp', {err, res})
          if (err) {
            clearInterval(timer)
            console.log({err})
            reject(err)
          } else if (res && res.blockNumber){
            clearInterval(timer)
            if (res.status === true) {
              message.destroy()
              message.success(`${type} Successfull!`)
              console.log('Contract call successfull', {res})
              resolve(txHash)
            } else {
              console.log('Contract call fail', {res})
              reject('Contract call fail')
            }
          }
        })
      }, 3000)
    }).on('error', (e) => {
      console.log('sendTxFailed', e)
      reject(e)
    })
  })
}

export default {
  sendTx
}
