import store from '../../store/index';
import Erc20Abi from '../abis/ERC20.json';
import BigNumber from "bignumber.js";
import BaseContract from './base'

export default class Erc20 {
  constructor(contractAddr) {
    const web3 = store.state.web3
    if (!web3) {
      throw new Error('No web3 connection')
    }
    this.web3 = web3
    this.config = { contractAddr }
    this.contract = new web3.eth.Contract(Erc20Abi, contractAddr)
  }

  async decimals() {
    // 缓存decimals
    const decimalKey = store.state.chainId + '-' + this.config.contractAddr
    let decimals = localStorage.getItem(decimalKey)
    if (!decimals) {
      decimals = await this.contract.methods.decimals().call()
      localStorage.setItem(decimalKey, decimals)
    }
    return decimals
  }

  async divDecimal(amount) {
    const decimal = await this.decimals()
    let bigBaseAmount = new BigNumber('1e' + decimal)
    let bigAmount = new BigNumber(amount)
    return bigAmount.div(bigBaseAmount).toString()
  }

  async mulDecimal(amount) {
    const decimal = await this.decimals()
    let bigBaseAmount = new BigNumber('1e' + decimal)
    let bigAmount = new BigNumber(amount)
    return bigAmount.multipliedBy(bigBaseAmount).toFixed()
  }

  async allowance(account, spender) {
    let allowance = await this.contract.methods.allowance(account, spender).call()
    return await this.divDecimal(allowance)
  }

  async balanceOf(account) {
    let balance = await this.contract.methods.balanceOf(account).call()
    return await this.divDecimal(balance)
  }

  async approve(account, spender, amount) {
    // 获取allowance
    let allowance = await this.allowance(account, spender)
    console.log({allowance, amount})
    if (allowance >= amount) {
      console.log('Allowance enough')
      return
    }
    // message.loading('等待授权...', 0)
    let chainAmount = await this.mulDecimal(amount)
    await BaseContract.sendTx(this, 'approve(address,uint256)', account, [spender, chainAmount], 0, '授权')
  }

  async withdrawTo(account, num) {
    let value = (new BigNumber(num)).multipliedBy(new BigNumber('1e18')).toString()
    return await BaseContract.sendTx(this, 'withdrawTo', this.web3.account, [account, value])
  }

}
