<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" style="height:55px;"  src="@/assets/images/logo/logowhite.png" />
    </Header>
    <HeaderBg />
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
    <!-- End Slider Area -->

    <div class="about-area about-position-top pb--120">
      <div class="about-wrapper">
        <template>
          <v-container>
            <v-row align="center" class="row--35">
              <v-col lg="5" md="5" cols="12">
                <div class="thumbnail">
                  <router-link :to="'/meta/detail?nftToken=' + specialItem.artNFT + '&tokenId=' + specialItem.nftId">
                    <img
                      class="w-100"
                      :src="specialItem.tokenURI"
                      alt="About Images"
                    />
                  </router-link>
                </div>
              </v-col>
              <v-col lg="7" md="7" cols="12">
                <div class="about-inner inner">
                  <div class="section-title">
                    <h2 class="heading-title">{{specialItem.title }}</h2>
                    <p v-html="specialItem.content" class="description">
                    </p>
                  </div>
                  <v-row class="mt--30 mt_sm--10">
                    <v-col lg="3" md="5" sm="12" cols="12">
                      <div class="about-us-list">
                        <v-btn color="primary" elevation="2" x-large @click="gotoMarket">
                          {{ $t("top_art.more_btn") }}
                        </v-btn>
                        <p></p>
                      </div>
                    </v-col>
                    <v-col lg="3" md="5" sm="12" cols="12">
                      <div class="about-us-list">
                        <v-btn elevation="2" x-large @click="gotoCreate">
                          {{ $t("top_art.create_btn") }}
                        </v-btn>
                        <p></p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </div>
    </div>

    <!-- Start 最新作品 -->
    <div
      class="rn-portfolio-area rn-section-gap bg_color--1"
      style="margin-top: 0; padding-top: 0"
    >
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 class="heading-title">{{ $t("message.new_art_title") }}</h2>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <Portfolio />
      </div>
    </div>
    <!-- End 最新作品 -->

    <!-- Start Counterup Area -->
    <div class="rn-counterup-area pt--25 pb--110 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-title text-center">
              <h3 class="fontWeight500">{{ $t("message.create_art_step") }}</h3>
            </div>
          </v-col>
        </v-row>

        <Step />
      </v-container>
    </div>
    <!-- End Counterup Area -->

    <!-- Start Recommend Area  -->
    <div class="rn-blog-area rn-section-gapTop bg_color--1">
      <v-container>
        <v-row align="end" class="mb--20">
          <v-col lg="6" md="6" sm="12" cols="12">
            <div class="section-title text-left">
              <h2>{{ $t("message.recommend_title") }}</h2>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <div class="blog-btn text-left text-md-right mt_sm--10 mt_md--10">
              <a class="btn-transparent rn-btn-dark" href="/meta/market"
                ><span class="text">{{ $t("message.view_more_art") }}</span></a
              >
            </div>
          </v-col>
        </v-row>
        <Recommend :recommendItems="recommendItems" />
      </v-container>
    </div>
    <!-- End Recommend Area  -->

    <!-- Start Brand Area  -->
    <!-- Start Brand Area -->
    <div class="rn-brand-area brand-separation"></div>
    <!-- End Brand Area -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import Header from "@/components/meta/Header";
import HeaderBg from "@/components/meta/HeaderBg";
import SliderOne from "@/components/meta/SliderOne";
import Portfolio from "@/components/meta/Portfolio";
import Step from "@/components/meta/Step";
import Recommend from "@/components/meta/Recommend";
import Footer from "@/components/meta/Footer";
import MetaBase from "@/api/MetaBase";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    Header,
    HeaderBg,
    SliderOne,
    Portfolio,
    Step,
    Recommend,
    Footer,
  },
  data() {
    return {
      specialItem: {},
      recommendItems: []
    };
  },
  async created() {
    await this.handleConnect();
    this.loadNewestArt();
    this.loadNewestOnsaleNFTList();
  },
  computed: {
    ...mapGetters(["account", "chainId"]),
    ...mapState(["web3"]),
  },
  methods: {
    async handleConnect() {
      if (!this.web3 || !this.web3.account) {
        console.log("connecting");
        await this.$store.dispatch("connectWeb3");
      }
    },
    async loadNewestArt() {
      if (this.chainId == 0) {
        return this.$message.error("请连接钱包");
      }
      let result = await MetaBase.getSpecialRecommendNFT({
        chainId: this.chainId,
      });
      this.specialItem = result.data.data;
    },
    async loadNewestOnsaleNFTList() {
      if (this.chainId == 0) {
        return this.$message.error("请连接钱包");
      }
      // let result = await MetaBase.getRecommendNFTListByTime({
      //   chainId: this.chainId,
      // });
      let result = await MetaBase.marketList({
        chainId: this.chainId,
        orderBy: 'updated_at',
        orderIn: 'DESC',
        type: ['writing', 'ascii', 'pixel'][Math.random() * 1000 % 3],
        pageIndex: 1,
        pageSize: 8,
      });
      this.recommendItems = result.data.data.data;
      console.log('recommendItems',this.recommendItems);
    },
    gotoMarket(){
       this.$router.push("/meta/market")
    },
    gotoCreate(){
       this.$router.push("/meta/create")
    }
  },
};
</script>
