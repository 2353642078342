<template>
  <footer class="footer-area footer-default">
    <div class="footer-wrapper">
      <v-row align="end" class="row--0">
        <v-col md="6" sm="12" cols="12">
          <div class="footer-left">
            <div class="inner">
              <span>Avatar</span>
              <h2>
                Improved ERC404 <br />
                PFP NFT
              </h2>
              <button @click="scrollToSection" class="rn-button-style--2"><span>Mint Now</span></button>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col md="6" sm="12" cols="12">
          <div class="footer-right" data-black-overlay="6">
            <v-row>
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12">
                <div class="footer-widget">
                  <h4>Quick Link</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList" :key="i">
                      <router-link v-if="nav.to" :to="nav.to">{{ nav.navItem }}</router-link>
                      <a v-else :href="nav.url" target="_blank">{{ nav.navItem }}</a>
                    </li>
                  </ul>
                </div>
              </v-col>
              <!-- End Single Widget  -->
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12" class="mt_mobile--30">
                <div class="footer-widget">
                  <h4>Contract us</h4>
                  <ul class="ft-link">
                    <li v-for="(mail, i) in mailList" :key="i">
                      <router-link v-if="nav.to" :to="nav.to">{{ nav.navItem }}</router-link>
                      <a v-else :href="nav.url" target="_blank">{{ nav.navItem }}</a>
                    </li>
                  </ul>

                  <div class="social-share-inner">
                    <ul
                      class="social-share social-style--2 d-flex justify-content-start liststyle mt--15"
                    >
                      <li v-for="(social, i) in socialList" :key="i">
                        <a :href="social.url" target="_blank">
                          <i class="fab" :class="social.icon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <!-- End Single Widget  -->

              <v-col lg="12">
                <div class="copyright-text">
                  <p>
                    Copyright © {{ new Date().getFullYear() }} Avatar Lab.
                    All Rights Reserved.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </footer>
</template>

<script>
  import goTo from "vuetify/es5/services/goto";
  export default {

    data() {
      return {
        socialList: [
          {
            icon: "fab fa-twitter",
            url: "https://twitter.com/ERC404_AVATAR",
          },
          // {
          //   icon: "fab fa-discord",
          //   url: "https://discord.gg/",
          // },
          {
            icon: "fab fa-github",
            url: "https://github.com/web3stars/Avatar-Contracts",
          },
          // {
          //   icon: "fab fa-telegram",
          //   url: "https://t.me/",
          // },
          {
            icon: "fab fa-medium",
            url: "https://medium.com/@avatar404"
          }
        ],
        navList: [
          {
            navItem: "NFT Trading Revolution",
            url: "https://medium.com/@avatar404/the-avatar-enhanced-erc404-protocol-the-quantum-nft-liquidity-model-ushers-in-a-trading-d18840a0cfef",
          },
          {
            navItem: "AVATAR",
            url: "https://medium.com/@avatar404/avatar-a-revolutionary-exploration-of-the-quantum-nft-liquidity-model-da59e44439c3"
          },
          {
            navItem: "Quantum NFT Liquidity Model",
            url:"https://medium.com/@avatar404/from-erc404-to-quantum-nft-liquidity-model-solving-the-nft-liquidity-challenge-0201420a0343"
          }
        ],
        mailList: [
          // {
          //   mailItem: "admin@example.com",
          //   to: "mailto:admin@example.com",
          // },
          // {
          //   mailItem: "hr@example.com",
          //   to: "mailto:hr@example.com",
          // },
        ],
      };
    },
    methods: {
      scrollToSection() {
        goTo("#mint-section");
      },
    },
  };
</script>
