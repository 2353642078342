import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";
// import Demo from "../views/Demo.vue";
import MainDemo from "../views/all-home-version/MainDemo";
import Main from "../views/meta/Main";

Vue.use(VueRouter);

const routes = [
  {
    path: "/", // /avatar
    name: "AvatarMain",
    meta: {
      title: "Avatar",
    },
    component: () => import("../views/avatar/Main.vue"),
  },
  {
    path: "/meta/createSuccess",
    name: "MetaCreateSuccess",
    meta: {
      title: "CreateSuccess",
    },
    component: () => import("../views/meta/CreateSuccess.vue"),
  },

  {
    path: "*",
    component: Missing,
    meta: {
      title: "404 || VueJS Multipurpose Template",
    },
  },
  {
    path: "/interactive-agency",
    name: "InterActiveAgency",
    meta: {
      title: "Interactive Agency || VueJS Multipurpose Template",
    },
    component: () => import("../views/all-home-version/InterActiveAgency.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
