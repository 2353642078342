<template>
  <div>
    <router-link :to="`/meta/detail?nftToken=${nftAddr}&tokenId=${tokenId}&ownerAddr=${ownerAddr}`">
      <a-card hoverable style="width: 100%;margin-top: 20px;">
        <img
            style="width: 100%;height:200px;object-fit:cover;border-bottom:1px solid #E8E8E8;"
            slot="cover"
            :src="tokenUri"
        />
        <a-card-meta
            :title="title? title : ''"
            :description="price"
        >
        </a-card-meta>
      </a-card>
    </router-link>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data: () => ({
  }),
  props: {
    nftAddr: String,
    price: [Number, String],
    tokenId: Number,
    tokenUri: String,
    ownerAddr: String,
    title: String
  },
};
</script>

<style lang="scss">
</style>
