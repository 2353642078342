<template>
  <footer class="footer-area footer-default">
    <div class="footer-wrapper">
      <v-row class="row--0">
        <v-col md="12" sm="12" cols="12">
          <div class="footer-right" data-black-overlay="6">
            <v-row>
              <!-- Start Single Widget -->
              <v-col lg="4" sm="4" cols="12">
                <div class="footer-widget">
                  <h4>{{ $t("footer.links") }}</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList" :key="i">
                      <router-link v-if="nav.to" :to="nav.to">{{ nav.navItem }}</router-link>
                      <a v-else :href="nav.url" target="_blank">{{ nav.navItem }}</a>
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col lg="4" sm="4" cols="12">
                <div class="footer-widget">
                  <h4>{{ $t("footer.links") }}</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in linkList" :key="i">
                      <router-link v-if="nav.to" :to="nav.to">{{ nav.navItem }}</router-link>
                      <a v-else :href="nav.url" target="_blank">{{ nav.navItem }}</a>
                    </li>
                  </ul>
                </div>
              </v-col>
              <!-- End Single Widget  -->
              <!-- Start Single Widget -->
              <v-col lg="4" sm="4" cols="12" class="mt_mobile--30">
                <div class="footer-widget">
                  <h4>{{ $t("footer.contact") }}</h4>
                  
                  <div class="social-share-inner">
                    <ul
                      class="social-share social-style--2 d-flex justify-content-start liststyle mt--15"
                    >
                      <li v-for="(social, i) in socialList" :key="i">
                        <a :href="social.url" target="_blank">
                          <i class="fab" :class="social.icon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <!-- End Single Widget  -->

              <v-col lg="12">
                <div class="copyright-text">
                  <p>
                    Copyright © {{ new Date().getFullYear() }} Avatar.
                    All Rights Reserved.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        socialList: [
          {
            icon: "fab fa-twitter",
            url: "https://twitter.com/ERC404_AVATAR",
          },
          // {
          //   icon: "fab fa-discord",
          //   url: "https://discord.gg/",
          // },
          {
            icon: "fab fa-github",
            url: "https://github.com/web3stars/Avatar-Contracts",
          },
          // {
          //   icon: "fab fa-telegram",
          //   url: "https://t.me/",
          // },
          {
            icon: "fab fa-medium",
            url: "https://medium.com/@avatar404"
          }
        ],
        navList: [
          {
            navItem: "Block Explorer",
            url: "https://polygonscan.com/address/0x323716ba4f356d9c35d6ff6caa34159622b2432a",
          },
        ],
        linkList: [
          {
            navItem: "NFT Trading Revolution",
            url: "https://medium.com/@avatar404/the-avatar-enhanced-erc404-protocol-the-quantum-nft-liquidity-model-ushers-in-a-trading-d18840a0cfef",
          },
          {
            navItem: "AVATAR",
            url: "https://medium.com/@avatar404/avatar-a-revolutionary-exploration-of-the-quantum-nft-liquidity-model-da59e44439c3"
          },
          {
            navItem: "Quantum NFT Liquidity Model",
            url:"https://medium.com/@avatar404/from-erc404-to-quantum-nft-liquidity-model-solving-the-nft-liquidity-challenge-0201420a0343"
          }
        ]
      };
    },
  };
</script>

<style scoped>
  ul.social-share.social-style--2 li a {
    font-size: 20px;
  }
</style>
