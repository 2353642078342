import Vue from 'vue'
import Vuex from 'vuex'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Web3 from "web3";
import {getChainConfig} from "../utils/baseUtil";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    web3: null,
  },
  mutations: {
    setWeb3(state, web3) {
      state.web3 = web3
    },
    setAccounts(state, accounts) {
      let account = accounts.length > 0 ? accounts[0] : null
      if (state.web3.account !== account) {
        state.web3 = Object.assign({}, state.web3, {account, accounts})
      }
    },
    setChainId(state, chainId) {
      if (state.web3 && state.web3.chainId !== chainId) {
        let chainName = getChainConfig(chainId).chainName
        state.web3 = Object.assign({}, state.web3, {chainId, chainName})
        console.log('setChainId', state.web3)
      }
    }
  },
  getters:{
    account:state=>{
      if(state.web3 && state.web3.account){
        return state.web3.account;
      }
      return '0x0';
    },
    chainId:state=>{
      if(state.web3 && state.web3.chainId){
        return state.web3.chainId;
      }
      return 0;
    }
  },
  actions: {
    async connectWeb3({dispatch}) {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "9aa3d95b3bc440fa88ea12eaa4456161" // required
          }
        }
      };
      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions // required
      });
      // web3Modal.clearCachedProvider()
      const provider = await web3Modal.connect();
      await dispatch('initWeb3', provider)

    },
    async fetchWeb3({dispatch}) {
      if (typeof window.web3 === 'undefined') {
        return new Error('Unable to connect to Metamask')
      }
      let provider = window.web3.currentProvider
      await dispatch('initWeb3', provider)
    },
    async initWeb3({commit}, provider) {
      provider.on("accountsChanged", (accounts) => {
        console.log('accountChanges', {accounts});
        commit('setAccounts', accounts)
      });
      provider.on("chainChanged", (chainId) => {
        console.log('chainChanged', {chainId});
        commit('setChainId', parseInt(chainId))
      });
      provider.on("connect", (info) => {
        console.log('connect', {info});
        commit('setChainId', parseInt(info.chainId))
      });
      provider.on("disconnect", (error) => {
        console.log('disconnect', {error});
        // commit('setChainId', 0)
      });
      // 实例化web3
      const web3 = new Web3(provider)
      web3.chainId = await web3.eth.getChainId()
      web3.accounts = await web3.eth.getAccounts()
      web3.account = web3.accounts[0]
      let config =  getChainConfig(web3.chainId)
      web3.chainName = config.chainName
      web3.fullItem = config
      // console.log({chainId, account: accounts[0]})
      commit('setWeb3', web3)
    }
  },
  modules: {
  }
})
