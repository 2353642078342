<template>
  <div>
    <v-navigation-drawer class="hidden-md-and-up" v-model="drawer" absolute temporary width="320">
      <v-list>
        <v-list-item class="pa-5"> 
          <v-spacer></v-spacer>
          <v-btn class="close-icon" icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)">
          </v-btn>
        </v-list-item>
        <v-list-item :ripple="false" to="/" link>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- laguage list -->
        <v-list-group v-for="(item, index) in languageList" :key="index">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link :ripple="false" v-for="child in item.items" :key="child.title">
            <v-list-item-content>
              <v-list-item-title v-text="$t(child.title)" @click="changeLangEvent(child.flag)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar dark elevation="0" color="transparent" absolute class="default-header">
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
        <span class="logo_title">
          Avatar
        </span>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
        <v-menu open-on-hover bottom min-width="240" offset-y transition="scroll-y-reverse-transition">
          <template v-slot:activator="{ on }">
            <v-btn :ripple="false" text v-on="on" to="/">
              Home
            </v-btn>
          </template>
        </v-menu>

        <a class="rn-btn" href="javascript:;" @click="handleTest" v-if="web3 && web3.chainName"
          style="margin-right: -30px">{{ web3.chainName }}</a>

        <a class="rn-btn" href="javascript:;" @click="handleConnect">
          <span v-if="web3 && web3.account">{{ web3.account | shortStr }}</span>
          <span v-else>{{ $t("step.title1") }}</span>
        </a>

        <a v-if="!web3 || !web3.account" class="rn-btn" href="javascript:;"
          @click="handleConnect">{{ $t("step.title1") }}</a>

        <!-- <a v-if="web3 && web3.account" class="rn-btn" href="javascript:;" @click="handleUnwrap" >
          {{web3.account | shortStr}}(J:0.1, WJ:12)
        </a> -->

      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
import { mapState } from "vuex";
import Erc20 from "../../utils/contracts/erc20"

export default {
  data: () => ({
    drawer: false,
    languageList: [
      {
        items: [
          { title: "language.en", flag: "en" },
          { title: "language.zh", flag: "zh" },
        ],
        title: "language.title"
      }
    ],
    icon: "menu",
    closeIcon: "x",
  }),

  created() {
    this.handleConnect()
  },

  computed: {
    ...mapState(['web3'])
  },

  methods: {
    async handleTest() {
      console.log('test')
      const { ethereum } = window
      const setChain = () => {
        if (ethereum && ethereum.request) {
          try {
            try {
              ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: "0x89" }],
              });
              console.log("You have succefully switched to Polygon network")
            } catch (switchError) {
              // This error code indicates that the chain has not been added to MetaMask.
              if (switchError.code === 4902) {
                console.log("This network is not available in your metamask, please add it")
              }
              console.error(switchError)
              console.log("Failed to switch to the network")
            }
          } catch (ex) {
            // console.log('Scroll installed')
          }
        }
      }
      setChain()
    },
    handleConnect() {
      if (!this.web3 || !this.web3.account) {
        console.log('connecting')
        this.$store.dispatch('connectWeb3')
      }
    },
    async handleUnwrap() {
      alert('unwrap')
      let wj = new Erc20('0x7fba9BB966189Db8C4fE33B7bf67Bfa24203c6AD')
      await wj.withdrawTo(this.web3.account, '0.1')
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    changeLangEvent(lang) {
      if (lang === 'en') {
        this.$i18n.locale = 'en-US';
      } else if (lang === 'zh') {
        this.$i18n.locale = 'zh-CN';
      }
    }
  },
};
</script>

<style lang="scss">
.feather-menu {
  color: #c6c9d8;
}

.logo_title {
  color: #fff;
  font-size: 38px;
  display: inline-block;
  margin-left: 20px;
}

@media screen and (max-width: 520px) {
  .logo_title {
    font-size: 30px;
  }

  .logo {
    width: 50px;
  }
}
</style>
