
import BaseModule from "./BaseModule"

class MetaBase extends BaseModule {

  importContract(params){
    return this.post("/meta/importContract", params)
  }

  myNftList(params){
    return this.post("/meta/myNftList", params)
  }

  marketDetail(params){
    return this.post("/meta/marketDetail", params)
  }

  marketList(params){
    return this.post("/meta/marketList", params)
  }

  transferList(params){
    return this.post("/meta/transferList", params)
  }

  fetchContent (params) {
    return this.post('/cryptobase/searchKeyword', params)
  }

  pinIPFSFile (params) {
    //先不PING，在上链的时候再PIN
    return this.post('/cryptobase/pinIPFSFile', params)
  }

  showNounImage() {
    return this.get("/cryptobase/showNounImage");
  }

  getSelectFonts() {
    return this.get("/cryptobase/getSelectFonts");
  }

  getBackgrounds() {
    return this.get("/cryptobase/getBackgrounds");
  }

  getUserNFTList(params){
    return this.post("/cryptobase/getUserNFTList", params)
  }

  getNftTradeList(params){
    return this.post("/cryptobase/getNftTradeList", params)
  }

  getUserNFTListByAuthor(params){
    return this.post("/cryptobase/getUserNFTListByAuthor", params)
  }

  getSingleNFT(params){
    return this.post("/cryptobase/getSingleNFT", params)
  }

  getSpecialRecommendNFT(params){
    return this.post("/cryptobase/getSpecialRecommendNFT", params)
  }
  getOnSaleNFTListByValue(params){
    return this.post("/cryptobase/getOnSaleNFTListByValue", params)
  }

  getOnsaleNFTListByTime(params){
    return this.post("/cryptobase/getOnsaleNFTListByTime", params)
  }

  getAllNFTListByTime(params){
    return this.post("/cryptobase/getAllNFTListByTime", params)
  }

  getRecommendNFTListByTime(params){
    return this.post("/cryptobase/getRecommendNFTListByTime", params)
  }

  // 录入认证信息
  addWriting (params) {
    return this.post('/cryptobase/addWriting', params)
  }

  // 录入认证信息
  addArtistWriting (params) {
    return this.post('/cryptobase/addArtistWriting', params)
  }

  uploadAsciiFile(formData, onUploadProgress){
    return this.post("/cryptobase/uploadAsciiImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }
  uploadPixelFile(formData, onUploadProgress){
    return this.post("/cryptobase/uploadPixelImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }
  uploadDrawFile(formData, onUploadProgress){
    return this.post("/cryptobase/uploadDrawImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }
  getNftDetail(formData){
    return this.post("/cryptobase/getNftDetail", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  }
}
export default new MetaBase()
